import { addMilliseconds, isBefore } from "date-fns";

import type { ProposalLineItemSearchModalOutput } from "~/business-areas/proposal/proposal.model";
import type { ProposalUpdateRequest } from "~~/model/proposal.model";

export type ProposalFormStatus = "SAVED" | "SAVING" | "GENERATING_PRESENTATION";

export const EMPTY_PROPOSAL_LINE_ITEM: Omit<
  ProposalUpdateRequest["line_items"][number],
  "id" | "order"
> = {
  title: "",
  format_label: "",
  content_type_label: "",
  outcomes: [],
  additional_contents: [],
  program: [],
  options: {
    has_anchoring: false,
  },
  teacher_overrides: {
    description: null,
    company_ids: null,
  },
  tags: [],
};

export function useProposalEditableView(
  proposal_id: DatabaseTable<"proposals">["id"],
) {
  const readQuery = useLazyFetch(`/api/proposals/list/${proposal_id}`);

  const form = reactive<ProposalUpdateRequest>({
    title: "",
    customer_company_name: "",
    hubspot_deal_id: undefined,
    line_items: [],
  });

  const writeQuery = useFetch(`/api/proposals/list/${proposal_id}`, {
    method: "PUT",
    body: computed(() => form),
    watch: false,
    immediate: false,
  });

  whenever(
    readQuery.data,
    (proposal) => {
      writeQuery.data.value = proposal;

      form.title = proposal.title;
      form.customer_company_name = proposal.customer_company_name;
      form.hubspot_deal_id = proposal.hubspot_deal_id;
      form.line_items = proposal.line_items.map((line) => {
        return {
          ...line,
          options: line.options ?? { has_anchoring: false },
          tags: line.tags.map((tag) => tag.id),
        };
      });

      watchDebounced(
        () => form,
        () => writeQuery.execute(),
        {
          debounce: 700,
          deep: true,
          immediate: false,
        },
      );
    },
    { once: true },
  );

  const presentationQuery = useAsyncData(
    `proposal_${proposal_id}_presentation`,
    async () => {
      await $fetch(`/api/proposals/list/${proposal_id}/presentation`, {
        method: "POST",
      });

      return readQuery.execute();
    },
    {
      immediate: false,
    },
  );

  const status = computed<ProposalFormStatus>(() => {
    if (presentationQuery.status.value === "pending")
      return "GENERATING_PRESENTATION";

    if (writeQuery.status.value === "pending") return "SAVING";

    return "SAVED";
  });

  function addLineItem() {
    form.line_items.push({
      ...EMPTY_PROPOSAL_LINE_ITEM,
      id: crypto.randomUUID(),
      order: form.line_items.length,
    });
  }

  function deleteProposal() {
    return useModalConfirmation({
      title: "Delete proposal",
      subtitle:
        "This action can't be undone. Linked presentation will be moved to trash.",
      async onConfirm() {
        await $fetch(`/api/proposals/list/${proposal_id}`, {
          method: "DELETE",
        });

        return navigateTo({ name: "proposals" });
      },
    });
  }

  function addLineItemFromExistingContent() {
    return useModal("proposalSearchableLineItemModal").open<
      ProposalLineItemSearchModalOutput[]
    >(
      { proposal: form },
      {
        onConfirm(items) {
          return form.line_items.push(
            ...items.map((item) => {
              return {
                ...item,
                order: form.line_items.length,
                id: crypto.randomUUID(),
                options: {
                  has_anchoring: false,
                },
              };
            }),
          );
        },
      },
    );
  }

  return {
    data: readQuery.data,
    form,
    status,

    addLineItem,
    addLineItemFromExistingContent,
    publish: presentationQuery.execute,
    hasUnpublishedChanges: computed(() => {
      if (!readQuery.data.value?.presentation_updated_at) return true;

      return [
        writeQuery.data.value?.updated_at,
        ...(writeQuery.data.value?.line_items.map((item) => item.updated_at) ??
          []),
      ]
        .filter(Boolean)
        .some((date) => {
          return isBefore(
            addMilliseconds(
              new Date(readQuery.data.value!.presentation_updated_at!),
              500,
            ),
            new Date(date),
          );
        });
    }),
    deleteProposal,
  };
}
